<template>
  <v-app>
    <v-app-bar app color="primary" dense flat dark>
      <v-toolbar-title>Solana Edge</v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="mr-2"
        >SOL: CF1SScA1JgEPBcgJAZQ9GxoQFPa2soh2taXLnvah4K1N</span
      >
    </v-app-bar>

    <v-main>
      <Home />
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/Home";

export default {
  name: "App",

  components: {
    Home,
  },

  data: () => ({
    //
  }),
};
</script>
