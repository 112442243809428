<template>
  <v-container fluid class="full-container">
    <v-row class="text-center">
      <v-col sm="12" md="6">
        <v-card>
          <v-card-title>
            Listings
            <v-spacer></v-spacer>
            <v-text-field
              v-model="listings.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
            <v-menu offset-y :nudge-width="200" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" dark icon v-bind="attrs" v-on="on">
                  <v-icon>filter_list</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-checkbox
                    v-model="listings.filters.verifiedOnly"
                    label="Verified Only"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-data-table
            class="listing-table"
            :headers="listings.headers"
            :items="filterListings"
            :search="listings.search"
            item-key="key"
            :items-per-page="15"
            :custom-filter="filterCommaSeparated"
            :loading="listings.data.length < 1"
            loading-text="Loading... Please wait"
            fixed-header
            :calculate-widths="true"
            height="calc(100vh - 200px)"
          >
            <template v-slot:item.imageURL="{ item }">
              <img :src="getCachedImageURL(item)" :alt="item.name" />
            </template>
            <template v-slot:item.verifiedMint="{ item }">
              <v-icon dark color="green" small v-if="item.verifiedMint != null"
                >verified</v-icon
              >
              <v-icon dark color="red" small v-if="item.verifiedMint == null"
                >new_releases</v-icon
              >
              <span v-if="item.verifiedMint">{{ item.verifiedMint.name }}</span>
              <span v-else-if="item.collection && item.collection.family">{{
                item.collection.family
              }}</span>
              <span v-else-if="item.collection && item.collection.name">{{
                item.collection.name
              }}</span>
              <span v-else>Unknown</span>
            </template>
            <template v-slot:item.floor="{ item }">
              <span v-if="item.verifiedMint"
                >{{ item.floor }} SOL ({{ item.fromFloor }}%)</span
              >
            </template>
            <template v-slot:item.howRareInfo="{ item }">
              <span v-if="item.howRareInfo">{{ item.howRareInfo.rank }}</span>
            </template>
            <template v-slot:item.timestamp="{ item }">
              {{ prettyDate(item.timestamp) }}
            </template>
            <template v-slot:item.purchaseURL="{ item }">
              <v-btn
                fab
                dark
                x-small
                color="primary"
                :href="item.purchaseURL"
                rel="noopener noreferrer"
                target="_blank"
              >
                <v-icon dark> shopping_cart </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col sm="12" md="6">
        <v-card>
          <v-card-title>
            Sales
            <v-spacer></v-spacer>
            <v-text-field
              v-model="sales.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
            <v-menu offset-y :nudge-width="200" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" dark icon v-bind="attrs" v-on="on">
                  <v-icon>filter_list</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-checkbox
                    v-model="sales.filters.verifiedOnly"
                    label="Verified Only"
                  ></v-checkbox>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-data-table
            class="sales-table"
            :headers="sales.headers"
            :items="filterSales"
            :search="sales.search"
            item-key="key"
            :items-per-page="15"
            :custom-filter="filterCommaSeparated"
            :loading="sales.data.length < 1"
            loading-text="Loading... Please wait"
            :calculate-widths="true"
            height="calc(100vh - 200px)"
          >
            <template v-slot:item.imageURL="{ item }">
              <img :src="getCachedImageURL(item)" :alt="item.name" />
            </template>
            <template v-slot:item.verifiedMint="{ item }">
              <v-icon dark color="green" small v-if="item.verifiedMint != null"
                >verified</v-icon
              >
              <v-icon dark color="red" small v-if="item.verifiedMint == null"
                >new_releases</v-icon
              >
              <span v-if="item.verifiedMint">{{ item.verifiedMint.name }}</span>
              <span v-else-if="item.collection && item.collection.family">{{
                item.collection.family
              }}</span>
              <span v-else-if="item.collection && item.collection.name">{{
                item.collection.name
              }}</span>
              <span v-else>Unknown</span>
            </template>
            <template v-slot:item.howRareInfo="{ item }">
              <span v-if="item.howRareInfo">{{ item.howRareInfo.rank }}</span>
            </template>
            <template v-slot:item.timestamp="{ item }">
              {{ prettyDate(item.timestamp) }}
            </template>
            <template v-slot:item.purchaseURL="{ item }">
              <v-btn
                fab
                dark
                x-small
                color="primary"
                :href="item.purchaseURL"
                rel="noopener noreferrer"
                target="_blank"
              >
                <v-icon dark> send </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
img {
  display: block;
  max-height: 64px;
  max-width: 64px;
  object-fit: cover;
  object-position: center;
}
.full-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.listing-table,
.sales-table {
  ::v-deep {
    // tbody {
    //   height: calc(100vh - 248px);
    //   vertical-align: top;
    // }
    // tr,
    // tr td {
    //   height: 64px !important;
    // }
    .mint-image {
      width: 64px;
      padding: 0 4px;
    }
  }
}
</style>

<script>
import { format } from "date-fns";

const delay = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default {
  name: "Home",

  data: () => ({
    listings: {
      data: [],
      headers: [
        {
          text: "",
          filterable: false,
          value: "imageURL",
          sortable: false,
          cellClass: "mint-image",
        },
        { text: "Verified", value: "verifiedMint" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Floor", value: "floor" },
        { text: "Market", value: "marketplace" },
        { text: "HowRare", value: "howRareInfo" },
        { text: "Posted", value: "timestamp" },
        { text: "Purchase", value: "purchaseURL" },
      ],
      search: "",
      filters: {
        verifiedOnly: false,
      },
    },
    sales: {
      data: [],
      headers: [
        {
          text: "",
          filterable: false,
          value: "imageURL",
          sortable: false,
          cellClass: "mint-image",
        },
        { text: "Verified", value: "verifiedMint" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Market", value: "marketplace" },
        { text: "HowRare", value: "howRareInfo" },
        { text: "Bought", value: "timestamp" },
        { text: "View", value: "purchaseURL" },
      ],
      search: "",
      filters: {
        verifiedOnly: false,
      },
    },
    connection: null,
  }),

  computed: {
    filterListings() {
      return this.listings.filters.verifiedOnly
        ? this.listings.data
            .slice()
            .filter((listing) => listing && listing.verifiedMint != null)
        : this.listings.data.slice();
    },

    filterSales() {
      return this.sales.filters.verifiedOnly
        ? this.sales.data
            .slice()
            .filter((listing) => listing && listing.verifiedMint != null)
        : this.sales.data.slice();
    },
  },

  methods: {
    prettyDate(date) {
      return format(date, "HH:mm:ss");
    },
    getCachedImageURL(item) {
      const imageURL = item.imageURL;

      if (process.env.NODE_ENV !== "production") {
        return imageURL;
      }

      try {
        if (imageURL == null) {
          window.DEBUG && console.log(item);
          return imageURL;
        }
        const [base, ...extra] = imageURL.split("?");
        const newURL = `${base}.png${
          extra.length > 0 ? `?${extra.join("")}` : ""
        }`;
        return `https://cdn.soledge.app/${newURL}`;
      } catch (error) {
        console.log(error);
        return imageURL;
      }
    },
    filterCommaSeparated(value, search, item) {
      if ((value == null && search == null) || value == null) {
        return false;
      }

      const searchSections = search
        .toLowerCase()
        .split(",")
        .filter((section) => section);

      const parsed = typeof value === "string" ? value : JSON.stringify(value);

      if (searchSections.some((section) => section.includes(":"))) {
        return searchSections.some((section) => {
          const sectionSplit = section.includes(":") && section.split(":");

          if (sectionSplit) {
            const hasAttribute =
              sectionSplit &&
              item?.attributes?.length > 0 &&
              item?.attributes?.some((attribute) => {
                const [trait = "", traitValue = ""] = sectionSplit;
                return (
                  attribute?.trait_type?.toLowerCase() ===
                    trait.toLowerCase() &&
                  attribute?.value
                    .toLowerCase()
                    .startsWith(traitValue.toLowerCase())
                );
              });

            return hasAttribute;
          }

          const containsSearch = parsed
            .toString()
            .toLowerCase()
            .includes(section);

          return containsSearch;
        });
      }

      return searchSections.some(
        (section) =>
          parsed
            .toString()
            .toLowerCase()
            .includes(section.toLowerCase()) ||
          JSON.stringify(item?.collection)
            .toLowerCase()
            .includes(section.toLowerCase())
      );
    },
    async connect() {
      if (this.connection != null) {
        this.connection.close();
        this.connection = null;
      }
      const socketURL = "wss://ws.soledge.app/";
      this.connection = new WebSocket(socketURL);
      this.connection.onmessage = (event) => {
        const { type, data } = JSON.parse(event?.data);
        const timestamp = new Date();
        const key = `${Date.now()}|${type}|${data.name}`;
        if (type === "LISTING") {
          this.listings.data = [
            { ...data, timestamp, key },
            ...this.listings.data.slice(0, 1000),
          ];
        }
        if (type === "SALE") {
          this.sales.data = [
            { ...data, timestamp, key },
            ...this.sales.data.slice(0, 1000),
          ];
        }
      };
      this.connection.onclose = async () => {
        await delay();
        this.connect();
      };
      this.connection.onerror = (e) => console.log(e);
    },
  },
  mounted() {
    this.connect();
  },
};
</script>
